// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
//ng build --configuration=tabungan  


export const environment = {
  production: false,
  
  /*gantiURL*/apiUrl: 'http://103.146.62.138:1342',//
  //apiUrl: 'http://lightsail.amanahummah.co.id:8082',
  // apiUrl : 'http://13.214.135.cd84:1342',
  //apiUrl : 'http://elb-1342-fd76ac8243c5c0d0.elb.ap-southeast-1.amazonaws.com:1342',
  //apiUrl: 'http://localhost:9091',
  /*gantiUidSekolah*/uid_sekolah: 0,

  // apiUrl: '/api/dev',
  // uid_sekolah: UID_SEKOLAH || -1,//uncomment ketika sudah jadi dist di main.js line 3577
   
  aplikasi: 'tabungan',
  MY_FORMATS: {
    parse: {
      dateInput: 'LL',
    },
    display: {
      dateInput: 'D MMMM YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  },
  formatTanggalDisplay: 'DD-MM-YYYY',
  formatTanggalWaktuDisplay: 'DD-MM-YYYY HH:mm:ss',
  formatTanggalDisplayHTML: 'dd-MM-yyyy',
  formatTanggalWaktuDisplayHTML: 'dd-MM-yyyy HH:mm:ss',
  formatTanggalApi: 'YYYY-MM-DD'
};
